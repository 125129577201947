<template>
  <div>
    <validation-observer
      v-slot="{ invalid, validate }"
      ref="entityFormObserver"
    >
      <b-form
        class="p-2"
        autocomplete="off"
        @submit.prevent="validate().then(submit)"
      >
        <slot :validate="validate" />

        <div
          v-if="editMode"
          class="mb-3 text-right"
        >
          <template v-for="extraAction in extraActions">
            <b-button
              v-if="extraActionShouldRender(extraAction)"
              :key="extraAction.title"
              :variant="extraAction.variant ? extraAction.variant : 'info'"
              :disabled="disableActions || (extraAction.validate && invalid)"
              :href="extraAction.href || null"
              class="mr-2"
              target="_blank"
              @click="handleExtraActionClick(extraAction)"
            >
              <feather-icon
                :icon="extraAction.icon"
                class="mr-50"
              />
              {{ $t(extraAction.title) }}
            </b-button>
          </template>

          <!-- Action Buttons -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="disableActions || invalid"
            variant="primary"
            class="mr-sm-1"
            type="submit"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t('Save') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { BButton, BForm } from 'bootstrap-vue'
import axios from '@/libs/axios'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    extraValidation: {
      type: Function,
      default: null,
    },
    submitCallback: {
      type: Function,
      default: null,
    },
    extraActions: {
      type: Array,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    returnToList: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { refFormObserver, getValidationState } = formValidation(
      props.clearEntityData,
    )

    return {
      refFormObserver,
      getValidationState,
    }
  },
  data() {
    return {
      customValidationMsg: '',
      disableActions: false,
    }
  },
  computed: {
    config() {
      return this.$store.getters[`${this.module}/config`]
    },
  },
  methods: {
    addEntity(data) {
      this.saveEntity('add', data)
    },
    updateEntity(data) {
      this.saveEntity('update', data)
    },
    saveEntity(saveType, data) {
      this.disableActions = true
      this.$store
        .dispatch(`${this.config.endpoint}/${saveType}Entity`, data)
        .then(response => {
          this.handleEntitySave(response)
          this.disableActions = false
        })
        .catch(error => {
          this.handleSaveError(error)
          this.disableActions = false
        })
    },
    handleEntitySave(response) {
      this.processEntitySave(
        response,
        this.$t('Entity Saved'),
        this.$t('Save completed successfully'),
      )
      this.$store.commit('app-common/clearCache')
    },
    processEntitySave(response, msgTitle, msgText) {
      if (response.data) {
        this.$parent.$parent.entity = response.data
        this.$emit('entitySaved', response.data)
        this.showToast({
          title: msgTitle,
          icon: 'CheckIcon',
          variant: 'success',
          text: msgText,
        })
      } else {
        const error = { message: this.$t('An unexpected error occurred') }
        this.handleSaveError(error)
      }
    },
    handleSaveError(error) {
      const msg = error.response?.data?.message || error.message

      const list = error.response?.data?.errors
        ? `<ul>${error.response.data.errors
          .map(li => `<li>${li}</li>`)
          .join('')}</ul>`
        : ''
      this.showToast({
        title: this.$t('Save Failure'),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: this.$t(msg) + list,
      })
    },
    applyExtraValidation(action) {
      if (this.extraValidation) {
        this.customValidationMsg = this.extraValidation(action)
        if (this.customValidationMsg.length > 0) {
          this.showToast({
            title: this.$t('Save Failure'),
            icon: 'fa-triangle-exclamation',
            variant: 'danger',
            text: this.customValidationMsg,
          })
          return false
        }
      }
      return true
    },
    async submit() {
      let isValid = await this.$refs.entityFormObserver.validate()
      isValid = this.applyExtraValidation()
      if (isValid) {
        if (this.entity.id) {
          this.updateEntity(this.entity)
        } else {
          this.addEntity(this.entity)
        }

        this.$nextTick(() => {
          this.$refs.entityFormObserver.reset()
          if (this.submitCallback) {
            this.submitCallback()
          }
          this.checkReturnToList()
        })
      }
    },

    checkReturnToList() {
      if (this.returnToList) {
        this.$router.push({ name: `${this.config.route}-list` })
      }
    },

    extraActionShouldRender(action) {
      return (
        (this.entity.id || action.insert) &&
        // If no authority validation is given or the validation passes continue
        // eslint-disable-next-line no-prototype-builtins
        (!action.hasOwnProperty('authorityValidation') ||
          action.authorityValidation) &&
        // If no render condition is given or the key matches the desired value continue
        (!action.renderCondition ||
          (action.renderCondition.exists &&
            this.entity[action.renderCondition.key] > 0) ||
          this.entity[action.renderCondition.key] ===
          action.renderCondition.value)
      )
    },

    handleExtraActionClick(action) {
      if (!action.href) {
        if (action.callback) {
          action.callback()
        } else {
          this.disableActions = true
          if (action.validate && !this.applyExtraValidation(action)) {
            this.disableActions = false
            return false
          }
          axios
            .get(`${this.config.endpoint}/${action.endpoint}/${this.entity.id}`)
            .then(response => {
              if (action.callback) {
                action.callback(response.data)
              } else {
                this.processEntitySave(
                  response,
                  this.$t('Action completed'),
                  this.$t('Action completed successfully'),
                )
              }
              this.disableActions = false
              this.checkReturnToList()
            })
            .catch(error => {
              this.handleSaveError(error)
              this.disableActions = false
            })
        }
      }
    },
  },
}
</script>
